.inf-portals-container{
    display: flex;
    flex-direction: column;
    gap: 48px;
    justify-content: space-between;
    align-items: center;
    overflow: auto;
}

.inf-portals-info{
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
}

.inf-secondary-text{
    color: #676878;
}

.inf-portals-selector{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px
}

.inf-portals-card-container{
    display: flex;
    flex-wrap: wrap;
    gap: 64px;
    justify-content: center;
}

.portal_card{
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px;
    border-radius: 12px;
    background: white;
    box-shadow: 0px 4px 16px 0px #00000029;
    width: 328px;
    max-width: 100%;
    text-align: left;
    cursor: pointer;
    position: relative;

}

.portal_card:hover{
    background: linear-gradient(180deg, #FFFFFF 3.21%, #E2EFFD 193.11%);
}

.disabled_card{
    opacity: 0.5;
}

.portal_card_heading{
    color: #323338;
    font-weight: 700;
}

.portal_card_info_text{
    font-size: 12px;
}

.inf-portal-logout{
    padding: 10px;
    display: flex;
    gap: 8px;
    margin-top: 32px;
    cursor: pointer;
    border-radius: 8px;
}

.inf-portal-logout:hover{
    background-color: #E6E9EE;
}

.portal_card_disable_message{
    position: absolute;
    bottom: -12px;
    transform: translateY(100%);
    text-align: center;
    font-size: 12px;
    color: #676878;
    width: 100%;
    left: 0px;
    display: none;
}

.disabled_card .portal_card_disable_message{
    display: flex;
    justify-content: center;
}